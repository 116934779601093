.middle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    
}

.middle .list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    height: 120px;
    margin-bottom: 18px;
    border-radius: 9px;
    padding: 18px;
    -webkit-box-shadow: 2px 3px 22px 5px rgba(0,0,0,0.15); 
    box-shadow: 2px 3px 22px 5px rgba(0,0,0,0.15);
}

.middle .list-item button {
    width: 20%;
    height: 32px;
    border: none;
    background-color: rgb(236, 94, 62);
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.middle .list-item button:hover {
    background-color: rgb(235, 70, 33);
}


@media screen and (max-width: 460px){
    .middle .list-item {
        width: 95%;
    }
    .middle .list-item h1 {
        font-size: 24px;
    }
}