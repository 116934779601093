.heading_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: blue;
    width: 100%;
    height: 200px;
}

.heading_container h1{
    margin-bottom: 22px;
}

.heading_container .input_container {
    display: flex;
    flex-direction: row;
    width: 50%;
}

.heading_container .input_container input {
    height: 34px;
    padding: 9px;
    font-size: 18px;
    width: 70%;
}

.heading_container .input_container button {
    height: 32px;
    padding: 9px;
    font-size: 18px;
    width: 30%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgb(77, 247, 77);
    color: white;
    border: none;
    cursor: pointer;
    transition: 0.2s all ease-in;
}

.heading_container .input_container button:hover {
    background-color: rgb(21, 192, 21);
}

@media screen and (max-width: 460px) {
    .heading_container .input_container {
        display: flex;
        flex-direction: column;
    }

    .heading_container .input_container input {
        width: 100%;
        margin-top: 12px;
    }

    .heading_container .input_container button {
        margin-top: 12px;
        width: 100%;
    }

}